import React, { useMemo } from "react";
import { PageContent } from "components";
import AddDeviceForm from "../component/Form/AddDeviceForm";
import { useCustomers } from "features/customers/hooks";
import { useGetTemplateGroupsQuery } from "../services";

const AddDevicePage = () => {
  const { data: customerData  } = useCustomers();
  const { data: templateData } = useGetTemplateGroupsQuery();

  const customerOptions = useMemo(() => {
    const opts = [];

    if (customerData && customerData.content) {
      customerData.content.forEach((d) => {
        if (d.name) {
          opts.push({ value: d.name, label: d.name });
        }
      });
    }

    return opts;
  }, [customerData]);

  const templateGroupOptions = useMemo(() => {
    if (!templateData || !templateData.content) return [];

    return templateData.content && templateData.content.map((d) => {
      return {
        value: d.id,
        label: d.name
      }
    })
  }, [templateData])

  return (
    <PageContent title="Add Device">
      <AddDeviceForm customerOptions={customerOptions} templateGroupOptions={templateGroupOptions} />
    </PageContent>
  );
};

export default AddDevicePage;
