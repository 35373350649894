import { ButtonLinkIcon, PrimeDropdown, RowItem } from "components";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  Nav,
  Row,
} from "reactstrap";
// import { useSdwanReference } from "features/sdwan/hooks/useSdwanReference";
import { useFirewallRuleFilter } from "features/sdwan/hooks/useFirewallRuleFilter";

const FirewallRuleFilter = ({ sn }) => {
  const [isOpen, setIsOpen] = useState(false);
//   const { options: typeOptions } = useSdwanReference("fw-alias-type");
  const { filters, setFilters } = useFirewallRuleFilter();
  const actionOptions = [
    {
      "label": "All",
      "value": "all"
    },
    {
      "label": "Pass",
      "value": "pass"
    },
    {
      "label": "Block",
      "value": "block"
    },
    {
      "label": "Reject",
      "value": "reject"
    }
  ]

  const interfaceOptions = [
    {
      "label": "All",
      "value": "all"
    },
    {
      "label": "LAN",
      "value": "lan"
    },
    {
      "label": "WAN",
      "value": "wan"
    },
  ]

  const {
    values,
    dirty,
    isSubmitting,
    handleReset,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      action: filters.action ? filters.action : 'all',
      interfaces : filters.interface ? filters.interface : 'all',
    },
    onSubmit: (formData) => {
      const { action, interfaces } = formData;
      setFilters({
        action: action !== 'all' ? action : undefined,
        interface : interfaces !== 'all' ? interfaces : undefined,
      });
    },
  });

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const onCancel = () => {
    setFilters({ action : undefined, interface: undefined });
    resetForm();
  }

  return (
    <Nav navbar>
      <Dropdown nav isOpen={isOpen} toggle={toggleMenu}>
        <DropdownToggle nav>
          <ButtonLinkIcon
            label=""
            icon="fa-filter"
            style={{ width: 50 }}
          />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 400 }} className="p-3">
            <Form onSubmit={handleSubmit} onReset={handleReset}>
                <RowItem
                label="Action"
                value={
                  <PrimeDropdown
                    options={actionOptions}
                    value={values.action}
                    onChange={(e) => setFieldValue("action", e.value)}
                    placeholder="Select Action"
                    // style={{maxWidth: '180px'}}
                  />
                }
                rightCol="col-7"
              />
              <RowItem
                label="Interface"
                value={
                  <PrimeDropdown
                      options={interfaceOptions}
                      value={values.interfaces}
                      onChange={(e) => setFieldValue("interfaces", e.value)}
                      placeholder="Select Interface"
                      // style={{maxWidth: '180px'}}
                  />
                }
                rightCol="col-7"
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="m-0"
                    block
                    color="default"
                    size="sm"
                    type="submit"
                    disabled={!dirty || isSubmitting}
                  >
                    Apply
                  </Button>
                  <Button
                    className="m-0 mt-2"
                    block
                    color="secondary"
                    size="sm"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

FirewallRuleFilter.propTypes = {
  sn: PropTypes.string.isRequired,
};
export default FirewallRuleFilter;
