import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProvisioningTemplateList from "./ProvisioningTemplateList";
import ProvisioningTemplateDetail from "./ProvisioningTemplateDetail";

const ProvisioningTemplateRoute = () => {
  return (
    <Switch>
      <Route
        exact
        path="/admin/provisioning-templates"
        component={ProvisioningTemplateList}
      />
      <Route
        exact
        path="/admin/provisioning-templates/:templateId"
        component={ProvisioningTemplateDetail}
      />
      <Redirect path="*" to="/admin/provisioning-templates" />
    </Switch>
  );
};

export default ProvisioningTemplateRoute;
