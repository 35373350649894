import { useCallback } from "react";
import { useCreateDeviceFirewallRuleMutation } from "../services";

export function useCreateFirewallRule(sn) {
  const [mutate, result] = useCreateDeviceFirewallRuleMutation();

  const bodyMap = (data) => {
    return {
      ...data,
      interface: Array.isArray(data.interface)
        ? data.interface.join(",")
        : data.interface,
    };
  };

  const createFirewallRule = useCallback(
    async (data) => {
      const body = bodyMap(data);

      if (sn) {
        await mutate({ sn, ...body }).unwrap();
      }
    },
    [mutate, sn]
  );

  return {
    createFirewallRule,
    isLoadingMutation: result.isLoading,
    ...result,
  };
}
