import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import classNames from "classnames";
import { Badge } from "reactstrap";
import OnlineStatus from "features/sdwan/component/OnlineStatus";
import { customStyles4 } from "utils/datatableStyles";

const ContentCell = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const TAG_LIMIT = 3; // Number of tags to show before "See more"

  // Handle falsy or empty array content
  if (!content || !Array.isArray(content) || content.length === 0) {
    return <span></span>;
  }

  // Limit the visible tags based on isExpanded state
  const visibleTags = isExpanded ? content : content.slice(0, TAG_LIMIT);

  return (
    <div style={{ margin: "5px 0" }}>
      {/* Render tags */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px", // Increase spacing between tags
          marginBottom: "8px",
          marginTop: "8px",
        }}
      >
        {visibleTags.map((tag, index) => (
          <span
            key={index}
            title={tag} // Hover to show full content
            style={{
              backgroundColor: "#EDEDED",
              color: "#333",
              borderRadius: "15px",
              padding: "6px 12px", // Adjust padding for better spacing
              fontSize: "12px",
              display: "inline-block",
              whiteSpace: "nowrap",
              maxWidth: "150px", // Limit tag width
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {tag}
          </span>
        ))}
      </div>

      {/* See more / See less button */}
      {content.length > TAG_LIMIT && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            background: "none",
            color: "#2065AC",
            border: "none",
            cursor: "pointer",
            fontSize: "12px",
            display: "flex",
            fontWeight: "bold",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {isExpanded ? "See less" : "See more"}
          <span
            style={{
              transform: isExpanded ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.3s",
            }}
          >
            ▼
          </span>
        </button>
      )}
    </div>
  );
};

const FirewallRulesTable = ({ data = [] }) => {
  const columns = useMemo(() => {
    const renderActionStatus = (row) => {
      let icon;
      let color;

      switch (row.action) {
        case "pass":
          icon = "fas fa-check-circle";
          color = "#2DCE89";
          break;
        case "block":
          icon = "fas fa-times-circle";
          color = "#F5365C";
          break;
        case "reject":
          icon = "fas fa-ban";
          color = "#F5365C";
          break;
        default:
          icon = "ℹ️";
          color = "gray";
      }
      return (
        <Badge className="badge-dot mr-1">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0",
            }}
          >
            <i className={classNames(icon)} style={{ fontSize: 14, color }} />
            <span
              className="text-dark"
              style={{
                marginTop: "5px",
                lineHeight: "1.5",
                marginLeft: "10px",
                whiteSpace: "normal",
                wordBreak: "break-word",
                textAlign: "left",
              }}
            >{`${
              row.action.charAt(0).toUpperCase() + row.action.slice(1)
            } from ${row.sourceNet} to ${row.destinationNet}`}</span>
          </div>
        </Badge>
      );
    };

    const renderStatus = (row) => {
      return <OnlineStatus type={row.enabled ? "enabled" : "disabled"} />;
    };

    const renderEnableLog = (bool) => {
      const icon = bool ? "fas fa-check-circle" : "fas fa-times-circle";
      const color = bool ? "#2DCE89" : "#F5365C";

      return (
        <div>
          <i className={classNames(icon)} style={{ fontSize: 14, color }}></i>
        </div>
      );
    };

    const renderAny = (str) => {
      return str === "any" ? <span className="text-muted">{str}</span> : str;
    };

    const renderPort = (start, end) => {
      if (start === "any") {
        return <span className="text-muted">{start}</span>;
      }

      if (start && end) {
        return `${start} - ${end}`
      }

      return start;
    };

    return [
      {
        id: "action",
        name: "action".toLocaleUpperCase(),
        selector: (row) => row?.action,
        cell: renderActionStatus,
        width: "200px",
      },
      {
        id: "enabled",
        name: "status".toLocaleUpperCase(),
        selector: (row) => renderStatus(row),
        wrap: true,
        width: "100px",
      },
      {
        id: "description",
        name: "description".toLocaleUpperCase(),
        selector: (row) => row.description,
        wrap: true,
        width: "250px",
      },
      {
        id: "interface",
        name: "interfaces".toLocaleUpperCase(),
        selector: (row) => (
          <ContentCell
            content={row.interface ? row.interface.split(",") : []}
          />
        ),
        wrap: true,
        width: "150px",
      },
      {
        id: "tcp",
        name: "tcp/ip version".toLocaleUpperCase(),
        selector: (row) => renderAny(row.ipProtocol),
        wrap: true,
        width: "150px",
      },
      {
        id: "protocol",
        name: "protocol".toLocaleUpperCase(),
        selector: (row) => renderAny(row.protocol),
        wrap: true,
        width: "150px",
      },
      {
        id: "source",
        name: "source".toLocaleUpperCase(),
        selector: (row) => renderAny(row.sourceNet),
        wrap: true,
        width: "150px",
      },
      {
        id: "sourcePortStart",
        name: "SRC. PORT",
        selector: (row) => renderPort(row.sourcePortStart, row.sourcePortEnd),
        wrap: true,
        width: "150px",
      },
      {
        id: "destination",
        name: "destination".toLocaleUpperCase(),
        selector: (row) => renderAny(row.destinationNet),
        wrap: true,
        width: "150px",
      },
      {
        id: "destinationPortStart",
        name: "DST. PORT",
        selector: (row) => renderPort(row.destinationPortStart, row.destinationPortEnd),
        wrap: true,
        width: "150px",
      },
      {
        id: "gateway",
        name: "gateway".toLocaleUpperCase(),
        selector: (row) => renderAny(row.gateway),
        wrap: true,
        width: "150px",
      },
      {
        id: "enableLog",
        name: "enable log".toLocaleUpperCase(),
        selector: (row) => renderEnableLog(row.enableLog),
        wrap: true,
        width: "150px",
      },
    ];
  }, []);

  return (
    <div>
      <DataTable
        data={data}
        responsive={true}
        columns={columns}
        persistTableHead
        noHeader
        striped
        pagination
        customStyles={{
          ...customStyles4,
          tableWrapper: {
            style: {
              minHeight: "400px",
            },
          },
        }}
      />
    </div>
  );
};

export default FirewallRulesTable;
