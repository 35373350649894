import { FullscreenLoading, PageContent } from "components";
import FirewallTab from "features/sdwan/component/Form/add-device/tabs/FirewallTab";
import GatewayGroupTab from "features/sdwan/component/Form/add-device/tabs/GatewayGroupTab";
import InterfaceTab from "features/sdwan/component/Form/add-device/tabs/InterfaceTab";
import { useGetTemplateGroupByIdQuery } from "features/sdwan/services";
import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import { useParams } from "react-router";
import { Card, CardBody } from "reactstrap";

const ProvisioningTemplateDetail = () => {
  const { templateId } = useParams();
  const { data, isLoading, isFetching } = useGetTemplateGroupByIdQuery(
    templateId,
    { skip: !templateId }
  );

  return (
    <PageContent title={`Template Group ${templateId} Details`}>
      <Card>
        <CardBody>
          {isLoading || isFetching ? (
            <FullscreenLoading />
          ) : (
            <TabView className="custom-tab">
              <TabPanel header="SD-WAN">
                <GatewayGroupTab
                  data={data?.templates?.gateway_groups}
                  gateways={data?.templates?.gateway_configurations}
                />
              </TabPanel>
              <TabPanel header="Firewall">
                <FirewallTab
                  firewallRulesData={data?.templates?.firewall_rules}
                  firewallAliasesData={data?.templates?.firewall_aliases}
                />
              </TabPanel>
              <TabPanel header="Interfaces">
                <InterfaceTab interfaces={data?.templates?.if_settings} />
              </TabPanel>
            </TabView>
          )}
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default ProvisioningTemplateDetail;
