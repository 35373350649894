// import PropTypes from "prop-types";
import React from "react";

const OnlineStatus = ({ type }) => {
  return (
    <div
      style={{
        height: 23,
        minWidth: 56,
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: type === "online" || type === "enabled" ? "#2DCE89" : "#8898AA",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        lineHeight: 1,
        fontSize: 10,
        fontWeight: 'bold'
      }}
    >
      {String(type).charAt(0).toUpperCase() + String(type).slice(1)}
    </div>
  );
};

// OnlineStatus.propTypes = {
//   type: PropTypes.oneOf(["online", "offline", "enabled", "disabled"]).isRequired,
// };

export default OnlineStatus;
