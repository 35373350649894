import { PageContent } from 'components'
import React from 'react'
import ProvisioningTemplateTable from './components/ProvisioningTemplateTable'

const ProvisioningTemplateList = () => {
  return (
    <PageContent title="Provisioning Templates">
      <ProvisioningTemplateTable />
    </PageContent>
  )
}

export default ProvisioningTemplateList