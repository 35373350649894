import { Formik } from "formik";
import React from "react";
import { Button, Card, CardBody, Col, Form, Row, Spinner } from "reactstrap";
import { TabView, TabPanel } from "primereact/tabview";
import FormItem from "../FormItem";
import {
  FullscreenLoading,
  TritronikInputText,
  TritronikSelect,
} from "components";
import "./style.css";
import GatewayGroupTab from "./add-device/tabs/GatewayGroupTab";
import FirewallTab from "./add-device/tabs/FirewallTab";
import InterfaceTab from "./add-device/tabs/InterfaceTab";
import {
  useCreateDeviceProvisionMutation,
  useLazyGetTemplateGroupByIdQuery,
} from "features/sdwan/services";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { history } from "utils";

const validationSchema = Yup.object().shape({
  sn: Yup.string().required("Serial Number required."),
  customer: Yup.string().required("Customer field required."),
  templateGroupId: Yup.string().required("Template must be selected."),
});

const AddDeviceForm = ({ customerOptions = [], templateGroupOptions = [] }) => {
  const [loadTemplate, { data, isLoading, isFetching }] =
    useLazyGetTemplateGroupByIdQuery();

  const [mutate, { isLoading: isSubmitting }] =
    useCreateDeviceProvisionMutation();

  const onSubmit = async ({ sn, customer: customerName, templateGroupId }) => {
    await mutate({ customerName, sn, templateGroupId }).unwrap();
    toast.success("Device provisioning created.");
    history.push("admin/sdwan/device");
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        sn: "",
        customer: "",
        templateGroupId: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        handleReset,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className="position-relative">
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col md="3">
                      <h3 className="mt-md-2 font-weight-medium">
                        Device Info
                      </h3>
                    </Col>
                    <Col md="9" sm="12">
                      <FormItem label="Serial Number" required>
                        <TritronikInputText name="sn" small required />
                      </FormItem>
                      <FormItem label="Customer" required>
                        <TritronikSelect
                          filter
                          showClear
                          required
                          name="customer"
                          editable
                          value={values.customer}
                          options={customerOptions}
                          onChange={(e) => setFieldValue("customer", e.value)}
                          invalid={touched.customer && errors.customer}
                          error={errors.customer}
                          hint="Select or type for new customer."
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col md="3" sm="12">
                      <h3 className="mt-md-2 font-weight-medium">
                        Provisioning Template
                      </h3>
                    </Col>
                    <Col md="9" sm="12">
                      <FormItem label="Template Group" required>
                        <TritronikSelect
                          filter
                          showClear
                          name="templateGroupId"
                          value={values.templateGroupId}
                          options={templateGroupOptions}
                          onChange={(e) => {
                            setFieldValue("templateGroupId", e.value);
                            if (e.value) {
                              loadTemplate(e.value);
                            }
                          }}
                          invalid={
                            touched.templateGroupId && errors.templateGroupId
                          }
                          error={errors.templateGroupId}
                          required
                          hint="Select template group."
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  {values.templateGroupId && values.templateGroupId !== "" ? (
                    <Row>
                      <Col md="12">
                        {isLoading || isFetching ? (
                          <FullscreenLoading />
                        ) : (
                          <TabView className="custom-tab">
                            <TabPanel header="SD-WAN">
                              <GatewayGroupTab
                                data={data?.templates?.gateway_groups}
                                gateways={
                                  data?.templates?.gateway_configurations
                                }
                              />
                            </TabPanel>
                            <TabPanel header="Firewall">
                              <FirewallTab
                                firewallRulesData={
                                  data?.templates?.firewall_rules
                                }
                                firewallAliasesData={
                                  data?.templates?.firewall_aliases
                                }
                              />
                            </TabPanel>
                            <TabPanel header="Interfaces">
                              <InterfaceTab
                                interfaces={data?.templates?.if_settings}
                              />
                            </TabPanel>
                          </TabView>
                        )}
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </Card>

              <Card
                className="mb-0 position-fixed rounded-0"
                style={{
                  padding: "0px 30px",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 999,
                }}
              >
                <CardBody
                  className="d-flex justify-content-end"
                  style={{ gap: 8 }}
                >
                  <Button
                    type="button"
                    color="secondary"
                    size="sm"
                    className="px-5"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    className="px-5"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting && (
                      <Spinner className="ml-2" color="light" size="sm" />
                    )}
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddDeviceForm;
