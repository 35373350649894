import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { SelectButton } from "primereact/selectbutton";
import FirewallRulesTable from "../components/FirewallRulesTable";
import FirewallAliasesTable from "../components/FirewallAliasesTable";
import "./style.css";

const FirewallTab = ({ firewallRulesData = [], firewallAliasesData = []}) => {
  const [selectedTab, setSelectedTab] = useState("Rules");

  return (
    <>
      <Row className="mb-3">
        <Col>
          <SelectButton
            className="p-button-sm"
            value={selectedTab}
            options={["Rules", "Aliases"]}
            onChange={(e) => setSelectedTab(e.value)}
          />
        </Col>
      </Row>
      <Row>
        {selectedTab === "Rules" ? (
          <Col>
            <FirewallRulesTable data={firewallRulesData} />
          </Col>
        ) : null}
        {selectedTab === "Aliases" ? (
          <Col>
            <FirewallAliasesTable data={firewallAliasesData} />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default FirewallTab;
