import { PageContent } from "components";
import React from "react";
import PageToolbar from "../component/PageToolbar";
import GatewayConfigurationForm from "../component/Form/GatewayConfigurationForm";
import { useSdwanReference } from "../hooks/useSdwanReference";
import { useCreateGateway } from "../hooks/useCreateGateway";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { history } from "utils";

const CreateGatewayConfiguration = () => {
  const { sn } = useParams();
  const { options: interfaceOptions } = useSdwanReference("list-interfaces", sn);
  const { options: ipFamilyOptions } = useSdwanReference("ip-family");
  const { createGateway, isLoading } = useCreateGateway(sn);

  const onCreate = async (values) => {
    try {
          await createGateway(values);
          toast.success("Command to create Gateway sent.");
          history.goBack();
        } catch (err) {
          toast.error(err.data.message || "Failed to create Gateway.");
        }
  };

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title="Create Gateway" className="mb-3" />
      <GatewayConfigurationForm
        interfaceOptions={interfaceOptions}
        ipFamilyOptions={ipFamilyOptions}
        onSubmit={onCreate}
        isLoading={isLoading}
      />
    </PageContent>
  );
};

export default CreateGatewayConfiguration;
