import React, { useMemo } from "react";
import {
  PrimeMultiSelect,
  TritronikInputText,
  TritronikSelect,
} from "components";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormText,
  Row,
  Spinner,
} from "reactstrap";
import FormItem from "./../FormItem";
import { history } from "utils";
import PropTypes from "prop-types";
import { InputSwitch } from "primereact/inputswitch";
import CollapsibleHelper from "../CollapsibleHelper";
import { RadioButton } from "primereact/radiobutton";

const actionOptions = [
  {
    label: "Pass",
    value: "pass",
  },
  {
    label: "Block",
    value: "block",
  },
  {
    label: "Reject",
    value: "reject",
  },
];

const directionOptions = [
  {
    name: "In",
    key: "in",
  },
  {
    name: "Out",
    key: "out",
  },
];

const ipOptions = [
  {
    name: "IPv4",
    key: "inet",
  },
  {
    name: "IPv6",
    key: "inet6",
  },
  {
    name: "IPv4 + IPv6",
    key: "inet46",
  },
];

const FirewallRuleForm = ({
  data,
  interfaceOptions,
  onSubmit,
  isLoading,
  protocolOptions,
  gatewayOptions,
  hostOptions,
  portOptions,
}) => {
  const initialValues = useMemo(
    () => ({
      enabled: data?.data.enabled ?? true,
      action: data?.data.action ?? "pass",
      interface: data?.data.interface ? data.data.interface.split(",") : [],
      protocol: data?.data.protocol ?? "any",
      gateway: data?.data.gateway ?? "any",
      direction: data?.data.direction ?? "in",
      ipProtocol: data?.data.ipProtocol ?? "inet",
      description: data?.data.description ?? "",
      enableLog: data?.data.enableLog ?? false,
      sourceNot: data?.data.sourceNot ?? false,
      destinationNot: data?.data.destinationNot ?? false,
      sourceType: data?.data.sourceType ?? "any",
      sourceNet: data?.data.sourceNet ?? "",
      sourceMask: data?.data.sourceMask ?? "",
      sourcePortStartType: data?.data.sourcePortStartType ?? "",
      sourcePortStart: data?.data.sourcePortStart ?? "",
      sourcePortEndType: data?.data.sourcePortEndType ?? "",
      sourcePortEnd: data?.data.sourcePortEnd?? "",
      destinationType: data?.data.destinationType ?? "any",
      destinationNet: data?.data.destinationNet ?? "",
      destinationMask: data?.data.destinationMask ?? "",
      destinationPortStartType: data?.data.destinationPortStartType ?? "",
      destinationPortStart: data?.data.destinationPortStart ?? "",
      destinationPortEndType: data?.data.destinationPortEndType ?? "",
      destinationPortEnd: data?.data.destinationPortEnd ?? "",
    }),
    [data]
  );

  const itemTemplate = (option) => {
    return (
      <div>
        <span>{option.label}</span>
        <span style={{ fontSize: '0.8em', color: option.category === 'Alias' ? 'blue' : 'gray' }}> ({option.category})</span> {/* Make category smaller */}
      </div>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, setFieldValue, handleSubmit }) => (
        <div className="position-relative">
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="3">
                  <h3 className="mt-md-2 font-weight-medium">
                    Rules Configuration
                  </h3>
                </Col>
                <Col md="9">
                  <FormItem label="Enable">
                    <InputSwitch
                      checked={values.enabled}
                      onChange={(e) => setFieldValue("enabled", e.value)}
                    />
                  </FormItem>
                  <FormItem label="Action">
                    <div className="w-100">
                      <TritronikSelect
                        name="action"
                        value={values.action}
                        options={actionOptions}
                        onChange={(e) => setFieldValue("action", e.value)}
                        invalid={touched.type && errors.type}
                        error={errors.type}
                      />
                      <CollapsibleHelper text="The difference between block and reject is that with reject, a packet (TCP RST or ICMP port unreachable for UDP) is returned to the sender,">
                        <FormText color="muted">
                          whereas with block the packet is dropped silently. In
                          either case, the original packet is discarded.
                        </FormText>
                      </CollapsibleHelper>
                    </div>
                  </FormItem>
                  <FormItem label="Interface">
                    <PrimeMultiSelect
                      name="interface"
                      value={values.interface}
                      options={interfaceOptions}
                      onChange={(e) => setFieldValue("interface", e.value)}
                      invalid={touched.type && errors.type}
                      error={errors.type}
                      filter
                    />
                  </FormItem>
                  <FormItem label="Direction">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      {directionOptions.map((direction) => {
                        return (
                          <div
                            key={direction.key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <RadioButton
                              inputId={direction.key}
                              name="direction"
                              value={direction.key}
                              onChange={(e) =>
                                setFieldValue("direction", e.value)
                              }
                              checked={values.direction === direction.key}
                            />
                            <label
                              htmlFor={direction.key}
                              style={{ margin: 0, fontSize: "14px" }}
                            >
                              {direction.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </FormItem>
                  <FormItem label="TCP/IP Version">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      {ipOptions.map((ip) => {
                        return (
                          <div
                            key={ip.key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <RadioButton
                              inputId={ip.key}
                              name="ipProtocol"
                              value={ip.key}
                              onChange={(e) => setFieldValue("ipProtocol", e.value)}
                              checked={values.ipProtocol === ip.key}
                            />
                            <label
                              htmlFor={ip.key}
                              style={{ margin: 0, fontSize: "14px" }}
                            >
                              {ip.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </FormItem>
                  <FormItem label="Protocol">
                    <TritronikSelect
                      name="protocol"
                      value={values.protocol}
                      options={protocolOptions}
                      onChange={(e) => setFieldValue("protocol", e.value)}
                      invalid={touched.type && errors.type}
                      error={errors.type}
                    />
                  </FormItem>
                  <FormItem label="Gateway">
                    <div className="w-100">
                      <TritronikSelect
                        name="gateway"
                        value={values.gateway}
                        options={
                          gatewayOptions?.length > 0
                            ? gatewayOptions.map((item) =>
                                item.label === "default"
                                  ? { ...item, label: "Nothing selected" }
                                  : item
                              )
                            : [{ label: "Nothing selected", item: "any" }]
                        }
                        onChange={(e) => setFieldValue("gateway", e.value)}
                        invalid={touched.type && errors.type}
                        error={errors.type}
                      />
                      <FormText color="muted">
                        Leave as 'Nothing selected' to use the system routing
                        table, or choose a gateway to utilize policy based
                        routing.
                      </FormText>
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="3">
                  <h3 className="mt-md-2 font-weight-medium">
                    Source & Destination
                  </h3>
                </Col>
                <Col md="9">
                  <FormItem label="Source">
                    <div className="w-100">
                      <TritronikSelect
                        name="sourceType"
                        value={values.sourceType}
                        options={hostOptions}
                        onChange={(e) => setFieldValue("sourceType", e.value)}
                        invalid={touched.sourceType && errors.sourceType}
                        optionLabel="label"
                        optionValue="value"
                        error={errors.sourceType}
                        itemTemplate={itemTemplate} 
                      />
                      {values.sourceType !== "any" && (
                        <div
                          className="d-flex flex-1 align-items-center w-100 mt-2"
                          style={{ columnGap: 8 }}
                        >
                          <TritronikInputText name="sourceNet" small />
                          <div>
                            <TritronikSelect
                              name="sourceMask"
                              value={values.sourceMask}
                              options={Array.from(
                                { length: values.ipProtocol === "inet" ? 32 : 128 },
                                (_, i) => ({ label: i + 1, value: `${i + 1}` })
                              )}
                              onChange={(e) =>
                                setFieldValue("sourceMask", e.value)
                              }
                              invalid={
                                touched.sourceMask && errors.sourceMask
                              }
                              error={errors.sourceMask}
                              style={{ width: 80 }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </FormItem>
                  <FormItem label="Invert Source">
                    <div className="w-100">
                      <InputSwitch
                        checked={values.sourceNot}
                        onChange={(e) => setFieldValue("sourceNot", e.value)}
                      />
                      <FormText color="muted">
                        Use this option to invert the sense of the match.
                      </FormText>
                    </div>
                  </FormItem>
                  <FormItem label="Source Port Range">
                    <div
                      className="d-flex align-items-top w-100"
                      style={{ columnGap: 8 }}
                    >
                      <div className="w-100">
                        <TritronikSelect
                          name="sourcePortStartType"
                          value={values.sourcePortStartType}
                          options={portOptions}
                          onChange={(e) =>
                            setFieldValue("sourcePortStartType", e.value)
                          }
                          invalid={
                            touched.sourcePortStartType && errors.sourcePortStartType
                          }
                          error={errors.sourcePortStartType}
                        />
                        <div
                          className="mt-2"
                          hidden={values.sourcePortStartType !== "other"}
                        >
                          <TritronikInputText
                            name="sourcePortStart"
                            small
                          />
                        </div>
                      </div>
                      <span style={{ fontSize: "small", marginTop: "5px" }}>
                        to
                      </span>
                      <div className="w-100">
                        <TritronikSelect
                          name="sourcePortEndType"
                          value={values.sourcePortEndType}
                          options={portOptions}
                          onChange={(e) =>
                            setFieldValue("sourcePortEndType", e.value)
                          }
                          invalid={
                            touched.sourcePortEndType && errors.sourcePortEndType
                          }
                          error={errors.sourcePortEndType}
                        />
                        <div
                          className="mt-2"
                          hidden={values.sourcePortEndType !== "other"}
                        >
                          <TritronikInputText name="sourcePortEnd" small />
                        </div>
                      </div>
                    </div>
                  </FormItem>
                  <hr />
                  <FormItem label="Destination">
                    <div className="w-100">
                      <TritronikSelect
                        name="destinationType"
                        value={values.destinationType}
                        options={hostOptions}
                        onChange={(e) => setFieldValue("destinationType", e.value)}
                        invalid={touched.destinationType && errors.destinationType}
                        error={errors.destinationType}
                        itemTemplate={itemTemplate}
                      />
                      {values.destinationType !== "any" && (
                        <div
                          className="d-flex flex-1 align-items-center w-100 mt-2"
                          style={{ columnGap: 8 }}
                        >
                          <TritronikInputText name="destinationNet" small />
                          <div>
                            <TritronikSelect
                              name="destinationMask"
                              value={values.destinationMask}
                              options={Array.from(
                                { length: values.ipProtocol === "inet" ? 32 : 128 },
                                (_, i) => ({ label: i + 1, value: i + 1 })
                              )}
                              onChange={(e) =>
                                setFieldValue("destinationMask", e.value)
                              }
                              invalid={
                                touched.destinationMask &&
                                errors.destinationMask
                              }
                              error={errors.destinationMask}
                              style={{ width: 80 }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </FormItem>
                  <FormItem label="Invert Destination">
                    <div className="w-100">
                      <InputSwitch
                        checked={values.destinationNot}
                        onChange={(e) =>
                          setFieldValue("destinationNot", e.value)
                        }
                      />
                      <FormText color="muted">
                        Use this option to invert the sense of the match.
                      </FormText>
                    </div>
                  </FormItem>
                  <FormItem label="Destination Port Range">
                    <div
                      className="d-flex align-items-top w-100"
                      style={{ columnGap: 8 }}
                    >
                      <div className="w-100">
                        <TritronikSelect
                          name="destinationPortStartType"
                          value={values.destinationPortStartType}
                          options={portOptions}
                          onChange={(e) =>
                            setFieldValue("destinationPortStartType", e.value)
                          }
                          invalid={
                            touched.destinationPortStartType &&
                            errors.destinationPortStartType
                          }
                          error={errors.destinationPortStartType}
                        />
                        <div
                          className="mt-2"
                          hidden={values.destinationPortStartType !== "other"}
                        >
                          <TritronikInputText
                            name="destinationPortStart"
                            small
                          />
                        </div>
                      </div>
                      <span style={{ fontSize: "small", marginTop: "5px" }}>
                        to
                      </span>
                      <div className="w-100">
                        <TritronikSelect
                          name="destinationPortEndType"
                          value={values.destinationPortEndType}
                          options={portOptions}
                          onChange={(e) =>
                            setFieldValue("destinationPortEndType", e.value)
                          }
                          invalid={
                            touched.destinationPortEndType &&
                            errors.destinationPortEndType
                          }
                          error={errors.destinationPortEndType}
                        />
                        <div
                          className="mt-2"
                          hidden={values.destinationPortEndType !== "other"}
                        >
                          <TritronikInputText
                            name="destinationPortEnd"
                            small
                          />
                        </div>
                      </div>
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ marginBottom: "7rem" }}>
            <CardBody>
              <Row>
                <Col md="3">
                  <h3 className="mt-md-2 font-weight-medium">
                    General Information
                  </h3>
                </Col>
                <Col md="9">
                  <FormItem label="Description">
                    <TritronikInputText name="description" small />
                  </FormItem>
                  <FormItem label="Enable Log">
                    <div className="w-100">
                      <InputSwitch
                        checked={values.enableLog}
                        onChange={(e) => setFieldValue("enableLog", e.value)}
                      />
                      <FormText color="muted">
                        Log packets that are handled by this rule. The firewall
                        has limited local log space, don't turn on logging for
                        everything.
                      </FormText>
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* Save and Cancel Buttons */}
          <Card
            className="mb-0 position-fixed rounded-0"
            style={{ padding: "0px 30px", bottom: 0, left: 0, right: 0 }}
          >
            <CardBody className="d-flex justify-content-end" style={{ gap: 8 }}>
              <Button
                color="secondary"
                size="sm"
                className="px-5"
                onClick={history.goBack}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="sm"
                className="px-5"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner size="sm" />
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </CardBody>
          </Card>
        </div>
      )}
    </Formik>
  );
};

FirewallRuleForm.propTypes = {
  data: PropTypes.object,
  typeOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};

export default FirewallRuleForm;
