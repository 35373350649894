import { PrimeSearchInput } from "components";
import { mapTriggerLevelDisplay } from "features/sdwan/utils";
import { orderBy } from "lodash";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Badge, Col, Row } from "reactstrap";
import { customStyles } from "utils/datatableStyles";

const GatewayGroupTab = ({ data = [] }) => {
  const [smartSearch, setSmartSearch] = useState("");

  const filteredData = useMemo(() => {
    if (smartSearch && smartSearch !== "") {
      return data.filter(
        ({ name, description, trigger }) =>
          name?.toLowerCase()?.includes(smartSearch.toLowerCase()) ||
          description?.toLowerCase()?.includes(smartSearch.toLowerCase()) ||
          trigger?.toLowerCase()?.includes(smartSearch.toLowerCase())
      );
    }

    return data;
  }, [data, smartSearch]);

  const columns = useMemo(() => {
    const renderTriggerLevel = ({ trigger }) => {
      return trigger ? mapTriggerLevelDisplay[trigger] : "-";
    };

    const renderGateway = (row) => {
      const { gateways: deviceNetworkGatewayGroupItems } = row;
      const sortByPriority = deviceNetworkGatewayGroupItems
        ? orderBy(deviceNetworkGatewayGroupItems, "tier")
        : [];
      const gateways = sortByPriority?.map((gw) => {
        return {
          priority: gw.tier,
          name: gw.gateway ?? "-",
          status: gw.status ?? null,
        };
      });

      return (
        <div>
          {gateways
            ? gateways.map((gw, i) => {
                return (
                  <div
                    key={`gw-item-${i}`}
                    className="d-flex align-items-center"
                  >
                    <div className="text-muted mr-3" style={{ width: 50 }}>
                      Tier {gw.priority}
                    </div>
                    <div className="d-flex align-items-center">
                      <Badge className="badge-dot mr-1">
                        <i style={{ backgroundColor: "gray" }} />
                        <span className="text-dark">{gw.name}</span>
                      </Badge>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      );
    };

    return [
      {
        id: "name",
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        id: "trigger",
        name: "Trigger",
        selector: (row) => row.trigger,
        cell: renderTriggerLevel,
        sortable: true,
      },
      {
        id: "gateways",
        name: "Gateways",
        selector: (row) => row.gateways,
        sortable: true,
        cell: renderGateway,
      },
      {
        id: "description",
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
      },
    ];
  }, []);

  return (
    <Row>
      <Col>
        <PrimeSearchInput
          onFilter={(text) => setSmartSearch(text)}
          className="mb-2"
          fullwidth
          size="sm"
          tooltip="Press 'Enter' to search."
          tooltipOptions={{ position: "top" }}
        />
        <DataTable
          data={filteredData}
          defaultSortFieldId="status"
          defaultSortAsc={false}
          columns={columns}
          paginationTotalRows={10}
          persistTableHead
          noHeader
          pagination
          customStyles={{
            ...customStyles,
            tableWrapper: {
              style: {
                minHeight: "400px",
              },
            },
          }}
        />
      </Col>
    </Row>
  );
};

export default GatewayGroupTab;
