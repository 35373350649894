import { useCallback } from "react";
import { useUpdateDeviceFirewallRuleMutation } from "../services";

export function useUpdateFirewallRule(sn, uuid) {
  const [mutate, result] = useUpdateDeviceFirewallRuleMutation();

  const bodyMap = (data) => {
    return {
      ...data,
      interface: Array.isArray(data.interface)
        ? data.interface.join(",")
        : data.interface,
    };
  };

  const update = useCallback(
    async (data) => {
      const body = bodyMap(data);

      if (sn) {
        await mutate({ sn, uuid, ...body }).unwrap();
      }
    },
    [mutate, sn, uuid]
  );

  return {
    update,
    isLoadingMutation: result.isLoading,
    ...result,
  };
}
