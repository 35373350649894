import React, { useMemo, /* useState */ } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, CardHeader } from "reactstrap";
import { customStyles } from "utils/datatableStyles";
import { useProvisioningTemplatesDatatable } from "../hooks/useProvisioningTemplatesDatatable";
import { ButtonLinkIcon, /* PrimeSearchInput */ } from "components";
import { RefreshButton } from "components/atoms/RefreshButton";
import { history } from "utils";

const ProvisioningTemplateTable = () => {
  // const [smartSearch, setSmartSearch] = useState("");
  const dt = useProvisioningTemplatesDatatable({
    // smartSearch: smartSearch && smartSearch !== "" ? smartSearch : undefined, // TODO: enable when backend ready
  });

  const columns = useMemo(() => {
    return [
      {
        id: "name",
        name: "TEMPLATE NAME",
        selector: (row) => row.name,
        width: "250px",
      },
      {
        id: "description",
        name: "DESCRIPTION",
        selector: (row) => row.description,
        allowOverflow: false,
      },
      {
        width: "40px",
        allowOverflow: true,
        button: true,
        cell: ({ id }) => {
          return <ButtonLinkIcon icon="fa-eye" onClick={() => history.push(`/admin/provisioning-templates/${id}`)} />;
        },
      },
    ];
  }, []);

  return (
    <Card>
      <CardHeader
        className="d-flex align-items-center justify-content-end border-0"
        style={{ columnGap: 4 }}
      >
        {/* <PrimeSearchInput
          onFilter={(value) => setSmartSearch(value)}
          className="mr-2"
          size="sm"
          tooltip="Press 'Enter' to search."
          tooltipOptions={{ position: "top" }}
        /> */}
        <RefreshButton
          isLoading={dt.isFetching || dt.isLoading}
          onClick={() => dt.refetch()}
        />
      </CardHeader>
      <CardBody>
        <DataTable
          data={dt.data}
          defaultSortFieldId="statusTranslated"
          defaultSortAsc={false}
          responsive={true}
          columns={columns}
          persistTableHead
          noHeader
          progressPending={dt.isLoading || dt.isFetching}
          pagination
          paginationServer
          paginationTotalRows={dt.totalRows}
          paginationPerPage={dt.pageSize}
          onChangePage={dt.onChangePage}
          onChangeRowsPerPage={dt.onChangeRowsPerPage}
          sortServer
          onSort={dt.onSort}
          customStyles={customStyles}
        />
      </CardBody>
    </Card>
  );
};

export default ProvisioningTemplateTable;
