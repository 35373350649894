import { StringParam, useQueryParams } from "use-query-params";

export function useFirewallRuleFilter() {
  const [filters, setFilters] = useQueryParams({
    action: StringParam,
    interface: StringParam,
  });

  return {
    filters,
    setFilters,
  };
}
