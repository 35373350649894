import OnlineStatus from "features/sdwan/component/OnlineStatus";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "utils/datatableStyles";

const ContentCell = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const TAG_LIMIT = 3; // Number of tags to show before "See more"

  // Handle falsy or empty array content
  if (!content || !Array.isArray(content) || content.length === 0) {
    return <span></span>;
  }

  // Limit the visible tags based on isExpanded state
  const visibleTags = isExpanded ? content : content.slice(0, TAG_LIMIT);

  return (
    <div style={{ margin: "5px 0" }}>
      {/* Render tags */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px", // Increase spacing between tags
          marginBottom: "8px",
          marginTop: "8px",
        }}
      >
        {visibleTags.map((tag, index) => (
          <span
            key={index}
            title={tag} // Hover to show full content
            style={{
              backgroundColor: "#EDEDED",
              color: "#333",
              borderRadius: "15px",
              padding: "6px 12px", // Adjust padding for better spacing
              fontSize: "12px",
              display: "inline-block",
              whiteSpace: "nowrap",
              maxWidth: "150px", // Limit tag width
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {tag}
          </span>
        ))}
      </div>

      {/* See more / See less button */}
      {content.length > TAG_LIMIT && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            background: "none",
            color: "#2065AC",
            border: "none",
            cursor: "pointer",
            fontSize: "12px",
            display: "flex",
            fontWeight: "bold",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {isExpanded ? "See less" : "See more"}
          <span
            style={{
              transform: isExpanded ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.3s",
            }}
          >
            ▼
          </span>
        </button>
      )}
    </div>
  );
};

const FirewallAliasesTable = ({ data = []}) => {
  const columns = useMemo(() => {
    const renderStatus = (row) => {
      return <OnlineStatus type={row.enabled ? "enabled" : "disabled"} />;
    };

    return [
      {
        id: "enabled",
        name: "Status".toLocaleUpperCase(),
        selector: (row) => row.enabled,
        sortable: true,
        sortField: "enabled",
        cell: renderStatus,
        width: "100px",
      },
      {
        id: "name",
        name: "Name".toLocaleUpperCase(),
        selector: (row) => row.name,
        sortable: true,
        sortField: "name",
      },
      {
        id: "type",
        name: "Type".toLocaleUpperCase(),
        selector: (row) => row.type,
        sortable: true,
        sortField: "type",
      },
      {
        id: "description",
        name: "description".toLocaleUpperCase(),
        selector: (row) => row.description,
        sortable: true,
        sortField: "description",
        wrap: true,
      },
      {
        id: "content",
        name: "content".toLocaleUpperCase(),
        selector: (row) => row.content,
        sortable: true,
        sortField: "content",
        cell: (row) => <ContentCell content={row.content} />,
      },
    ];
  }, []);
  return (
    <div>
      <DataTable
        data={data}
        defaultSortFieldId="statusTranslated"
        defaultSortAsc={false}
        responsive={true}
        columns={columns}
        persistTableHead
        noHeader
        pagination
        customStyles={{
          ...customStyles,
          tableWrapper: {
            style: {
              minHeight: "400px",
            },
          },
        }}
      />
    </div>
  );
};

export default FirewallAliasesTable;
