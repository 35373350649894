import React, { useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import { ConfigureInterfaceColumn } from "../component";
import { RefreshButton } from "components/atoms/RefreshButton";
import {
  ButtonLinkIcon,
  MyButton,
  PageContent,
  PrimeSearchInput,
} from "components";
import { customStyles } from "utils/datatableStyles";
import { history, paginationRowsPerPageOptions } from "utils";
import { useDeviceDatatable } from "features/device";
import { useTableColumnConfig } from "../hooks/useTableColumnConfig";

const defaultVisibleColumn = [
  "sn",
  "status",
  "customerName",
  "networkGatewayGroups",
  "interfaces",
  "updateModified",
];

const columnOptions = [
  { className: "text-xs", value: "status", label: "Status" },
  { className: "text-xs", value: "sn", label: "Serial Number" },
  { className: "text-xs", value: "customerName", label: "Customer" },
  {
    className: "text-xs",
    value: "networkGatewayGroups",
    label: "Gateway Group",
  },
  { className: "text-xs", value: "interfaces", label: "Interfaces" },
  { className: "text-xs", value: "updateModified", label: "Last Updated" },
];

const DeviceList = () => {
  const [visibleColumns, setVisibleColumns] = useTableColumnConfig(
    "sdwan-devices-table-config",
    defaultVisibleColumn
  );
  const [smartSearch, setSmartSearch] = useState("");
  const {
    data,
    isLoading,
    isFetching,
    totalRows,
    pageSize,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
    refetch,
  } = useDeviceDatatable({
    type: "BKHD-G30W-1264",
    smartSearch: smartSearch && smartSearch !== "" ? smartSearch : undefined,
  });

  const onSubmitConfigurationColumns = (columns) => {
    setVisibleColumns(columns);
  };

  const columns = useMemo(() => {
    const renderStatus = (row) => {
      const { connectionStatus, isSynchronized } = row;
      const color = !isSynchronized ? "#8898AA" : (connectionStatus === "Connected" ? "#2DCE89" : "#F5365C");
      const text = !isSynchronized ? "Unsynchronized" : (connectionStatus === "Connected" ? "Online" : "Offline");

      return (
        <div style={{ backgroundColor: color, color: "#FFFFFF" }} className="px-3 py-1 rounded">
          {text}
        </div>
      );
    };

    const renderInterface = ({ interfaces }) => {
      const getCount = (field, value) => {
        if (interfaces && Array.isArray(interfaces)) {
          return interfaces.filter((v) => v[field] === value)?.length;
        }

        return 0;
      };

      const down = getCount("status", "down");
      const up = getCount("status", "up");
      const noCarrier = getCount("status", "no carrier");
      const total =
        interfaces && Array.isArray(interfaces) ? interfaces.length : 0;

      return (
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <span>{total} Interfaces</span>
          <div className="d-flex align-items-center" style={{ columnGap: 8 }}>
            <div className="d-flex align-items-center" style={{ columnGap: 4 }}>
              <i
                className="fas fa-arrow-up"
                style={{ color: "#2DCE89", fontSize: 12 }}
              ></i>
              <span className="text-muted">{up}</span>
            </div>
            <div className="d-flex align-items-center" style={{ columnGap: 4 }}>
              <i
                className="fas fa-arrow-down"
                style={{ color: "#F5365C", fontSize: 12 }}
              ></i>
              <span className="text-muted">{down}</span>
            </div>
            <div className="d-flex align-items-center" style={{ columnGap: 4 }}>
              <i
                className="fas fa-moon text-"
                style={{ color: "#8898AA", fontSize: 12 }}
              ></i>
              <span className="text-muted">{noCarrier}</span>
            </div>
          </div>
        </div>
      );
    };

    const renderAction = ({ sn }) => {
      const openInterfaces = () =>
        history.push(`/admin/sdwan/device/${sn}/interfaces`);
      const openGateways = () =>
        history.push(`/admin/sdwan/device/${sn}/gateways/configurations`);
      const openFirewall = () =>
        history.push(`/admin/sdwan/device/${sn}/firewall/rules`);

      return (
        <div className="mr-auto">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <i className="fas fa-ellipsis-v" style={{ fontSize: 14 }}></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={openInterfaces}>Interface</DropdownItem>
              <DropdownItem onClick={openGateways}>Gateway</DropdownItem>
              <DropdownItem onClick={openFirewall}>Firewall</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const renderGatewayGroups = ({ networkGatewayGroups }) => {
      if (!networkGatewayGroups) return "-";

      return `${networkGatewayGroups.length} Groups`;
    };

    const renderUpdateModified = ({ updateModified }) => {
      return updateModified ? moment(updateModified).fromNow() : "-";
    };

    return [
      {
        id: "status",
        name: "STATUS",
        sortField: "connectionStatus",
        selector: (row) => row?.connectionStatus,
        left: true,
        cell: renderStatus,
        omit: visibleColumns?.includes("status") ? false : true,
        sortable: true,
      },
      {
        id: "sn",
        name: "SERIAL NUMBER",
        sortField: "sn",
        selector: (row) => row?.sn,
        left: true,
        omit: visibleColumns?.includes("sn") ? false : true,
        sortable: true,
      },
      {
        id: "customerName",
        name: "CUSTOMER",
        sortField: "customerName",
        selector: (row) => row?.customerName,
        left: true,
        omit: visibleColumns?.includes("customerName") ? false : true,
        sortable: true,
      },
      {
        id: "networkGatewayGroups",
        name: "GATEWAY GROUP",
        sortField: "gatewayGroup",
        selector: (row) => row?.gatewayGroup,
        cell: renderGatewayGroups,
        left: true,
        omit: visibleColumns?.includes("networkGatewayGroups") ? false : true,
        sortable: false,
      },
      {
        id: "interfaces",
        name: "INTERFACES",
        sortField: "interfaces",
        selector: (row) => row?.interfaces,
        cell: (row) => renderInterface(row),
        left: true,
        omit: visibleColumns?.includes("interfaces") ? false : true,
        sortable: false,
      },
      {
        id: "updateModified",
        name: "LAST UPDATED",
        sortField: "updateModified",
        selector: (row) => row?.updateModified,
        cell: renderUpdateModified,
        left: true,
        omit: visibleColumns?.includes("updateModified") ? false : true,
        sortable: true,
      },
      {
        width: "40px",
        cell: renderAction,
        allowOverflow: true,
        button: true,
      },
    ];
  }, [visibleColumns]);

  return (
    <PageContent title="Devices">
      <Card>
        <div>
          <CardHeader
            className="d-flex align-items-center justify-content-end py-2 border-0"
            style={{ columnGap: 4 }}
          >
            <PrimeSearchInput
              onFilter={(value) => setSmartSearch(value)}
              className="mr-4"
              size="sm"
              tooltip="Press 'Enter' to search."
              tooltipOptions={{ position: "top" }}
            />
            <ConfigureInterfaceColumn
              onSubmit={onSubmitConfigurationColumns}
              columnOptions={columnOptions}
              visibleColumns={visibleColumns}
            />
            <RefreshButton
              isLoading={isFetching || isLoading}
              onClick={() => refetch()}
            />
            <ButtonLinkIcon hidden label="Filters" icon="fa-filter" />
            <ButtonLinkIcon hidden icon="fa-file-csv" iconType="fa" />
            <ButtonLinkIcon hidden icon="fa-file-excel" iconType="fa" />
            <MyButton
              size="sm"
              onClick={() => history.push(`/admin/sdwan/device/new`)}
            >
              Add Device
            </MyButton>
          </CardHeader>
        </div>
        <CardBody>
          <DataTable
            defaultSortFieldId="sn"
            defaultSortAsc={true}
            data={data}
            progressPending={isLoading || isFetching}
            responsive={true}
            striped
            highlightOnHover
            noHeader
            persistTableHead
            columns={columns}
            customStyles={customStyles}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            sortServer
            onSort={onSort}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default DeviceList;
