import { useGetTemplateGroupsQuery } from "features/sdwan/services";
import { usePagination } from "hooks";
import { useMemo } from "react";

export function useProvisioningTemplatesDatatable(params = {}) {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination();

  const { data: paginatedResult, ...query } = useGetTemplateGroupsQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ? sortFields : undefined,
    sortOrder: sortOrder ? sortOrder : undefined,
    ...params
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    pageSize,
    totalRows,
    ...query,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}
