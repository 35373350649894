import { useCallback } from "react";
import { useUpdateDeviceGatewayMutation } from "../services";

export function useUpdateGateway(sn) {
  const [mutate, result] = useUpdateDeviceGatewayMutation();

  function convertValuesToString(obj) {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, String(value)])
    );
}

  const updateGateway = useCallback(
    async (uuid, data) => {
      const body = convertValuesToString(data)
      
      if (sn && uuid) {
        await mutate({ sn, uuid, body }).unwrap();
      }
    },
    [mutate, sn]
  );

  return {
    updateGateway,
    ...result,
  };
}
